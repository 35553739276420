import mixins from '@/mixins'
import course from '@/mixins/course'
import { mapActions, mapGetters } from 'vuex'

import Role from './role/list/index.vue'

export default {
  name: 'roles',
  mixin: [course, mixins],
  data () {
    return {
      activeTab: 'scored-points',
      roleId: null
    }
  },
  components: {
    Role
  },
  computed: {
    ...mapGetters({
      setting: 'courses/setting',
      list: 'coursesRoles/list'
    }),
    tabs () {
      return this.roles.map(item => ({
        ...item,
        disabled: false,
        name: item.title,
        key: item.title
      }))
    },
    roles () {
      return this.setting.courses.roles
    }
  },
  created () {
    this.setCourseStep(7)
    this.fetchList({
      courseId: this.$route.params.course_id,
      roleId: this.roles[0].id
    })
    this.roleId = this.roles[0].id
  },
  methods: {
    ...mapActions({
      fetchList: 'coursesRoles/GET_LIST'
    }),
    handleActiveTab (activeTab, i) {
      activeTab = i.key
      this.fetchList({
        courseId: this.$route.params.course_id,
        roleId: i.id
      })
      this.roleId = i.id
    }
  }
}
