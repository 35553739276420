import { mapActions, mapGetters, mapMutations } from 'vuex'

import { debounce } from 'lodash'
import { courseSettingCertificatesTableHead } from '@/modules/courses/helpers/setting'

import DialogView from '../dialog-view/index.vue'

export default {
    name        : 'courses-settings-certificates-list',
    data () {
        return {
            removingItem    : null,
            showRemoveDialog: false
        }
    },
    components  : {
        DialogView
    },
    computed    : {
        ...mapGetters({
            list            : 'coursesCertificates/list',
            limit           : 'coursesCertificates/limit',
            listLength      : 'coursesCertificates/listLength',
            isLoading       : 'coursesCertificates/isLoading',
            isListLoading   : 'coursesCertificates/isListLoading',
            openDialogView  : 'coursesCertificates/openDialogView'
        }),
        courseSettingCertificatesTableHead
    },
    created () {
        this.fetchList({
            courseId: this.$route.params.course_id
        })
    },
    methods     : {
        ...mapActions({
            fetchList   : 'coursesCertificates/CERTIFICATES_GET_LIST',
            remove      : 'coursesCertificates/CERTIFICATES_DELETE'
        }),
        ...mapMutations({
            changeDialogView        : 'coursesCertificates/CERTIFICATES_CHANGE_DIALOG_VIEW',
            setCurrentItem          : 'coursesCertificates/CERTIFICATES_SET_ITEM',
            setList                 : 'coursesCertificates/CERTIFICATES_SET_LIST',
            changeFilterTemplates   : 'settingTemplates/CHANGE_LIST_FILTER'
        }),
        changeDialog (status = false, item = null) {
            this.setCurrentItem(item)
            this.changeDialogView(status)
        },
        changeDialogDelete (item) {
            this.removingItem = item;
            this.removingItem.courseId = this.$route.params.course_id;
            
            this.showRemoveDialog = !this.showRemoveDialog;
        },
        removeLocal () {
            this.remove(this.removingItem).then(() => {
                this.showRemoveDialog = false;
                
                this.$toasted.success(this.$t('success_deleted'));
            })
        },
        sendRequest: debounce(function () {
            this.fetchList({
                courseId: this.$route.params.course_id
            })
        }, 1000)
    },
    destroyed () {
        this.setList([]);
        
        this.changeFilterTemplates({
            key     : 'keywords',
            value   : ''
        })
    }
}
