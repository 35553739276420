import mixins from '@/mixins'
import course from '@/mixins/course'

import ScoredPoints from './pages/scored-points/list/index.vue'
import Certificates from './pages/certificates/list/index.vue'
import EmailTemplates from './pages/email-templates/list/index.vue'
import General from './pages/general/index.vue'
import Roles from './pages/roles/index.vue'

export default {
  name: 'courses-setting',
  mixin: [course, mixins],
  data () {
    return {
      activeTab: 'scored-points'
    }
  },
  components: {
    ScoredPoints,
    General,
    Certificates,
    EmailTemplates,
    Roles
  },
  computed: {
    tabs () {
      return [
        {
          disabled: false,
          name: 'General',
          key: 'general'
        },
        {
          disabled: false,
          name: 'Roles',
          key: 'roles'
        },
        {
          disabled: false,
          name: 'Scored points',
          key: 'scored-points'
        },
        {
          disabled: false,
          name: 'Certificates',
          key: 'certificates'
        },
        {
          disabled: false,
          name: 'Email templates',
          key: 'email-templates'
        }
      ]
    }
  },
  created () {
    this.setCourseStep(7)
  }
}
