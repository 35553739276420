export function courseSettingCertificatesTableHead () {
    return [
        {
            text    : 'Created at',
            value   : 'createdAt'
        },
        {
            text    : 'Name',
            value   : 'name'
        },
        {
            text    : 'Points',
            value   : 'points'
        },
        {
            text    : 'Description',
            value   : 'description'
        },
        {
            text    : 'Actions',
            sortable: false,
            value   : 'actions'
        }
    ]
}

export function courseSettingsEmailTemplatesTableHead () {
	return [
		{
			text    : 'Id',
			value   : 'id'
		},
		{
			text    : 'Event name',
			value   : 'event'
		},
		{
			text    : 'Template name',
			value   : 'template'
		},
		{
			text    : 'Actions',
			sortable: false,
			value   : 'actions'
		}
	]
}

export function courseSettingScoredPointsTableHead () {
    return [
        {
            text    : 'Created at',
            value   : 'createdAt'
        },
        {
            text    : 'User full name',
            value   : 'userFullName'
        },
        {
            text    : 'Points',
            value   : 'points'
        },
        {
            text    : 'Description',
            value   : 'description'
        },
        {
            text    : 'Author',
            value   : 'adminFullName'
        },
        {
            text    : 'Actions',
            sortable: false,
            value   : 'actions'
        }
    ]
}

export function courseSettingRolesTableHead () {
    return [
        {
            text    : 'Name',
            value   : 'name'
        },
        {
            text    : 'Actions',
            sortable: false,
            value   : 'actions'
        }
    ]
}
