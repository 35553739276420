import { mapActions, mapGetters, mapMutations } from 'vuex'

import { debounce } from 'lodash'
import { courseSettingRolesTableHead } from '@/modules/courses/helpers/setting'

export default {
  name: 'courses-settings-scored-points-list',
  props: {
    roleId: {
      type: Number,
      default: null
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      page: 1,
      removingItem: null,
      showRemoveDialog: false,
      // filters: {
      //   keywords: '',
      //   courseId: this.$route.params.course_id
      // },
      search: '',
      searchInput: ''
    }
  },
  computed: {
    ...mapGetters({
      // list: 'coursesRoles/list',
      // limit: 'coursesScoredPoints/limit',
      // listLength: 'coursesScoredPoints/listLength',
      isLoading: 'coursesScoredPoints/isLoading',
      isListLoading: 'coursesScoredPoints/isListLoading',
      openDialogView: 'coursesScoredPoints/openDialogView',
      users: 'user/users',
      usersLoading: 'user/usersLoading'
    }),
    courseSettingRolesTableHead
  },
  created () {
    // this.fetchCourseUsersIds({ id: this.$route.params.course_id })
    this.fetchUsers({
      keywords: ''
    })
  },
  methods: {
    ...mapActions({
      create: 'coursesRoles/CREATE',
      // fetchCourseUsersIds: 'courses/GET_COURSE_USERS_IDS',
      remove: 'coursesRoles/DELETE',
      fetchUsers: 'user/GET_USERS'
    }),
    ...mapMutations({
      // changeSkip: 'coursesScoredPoints/CHANGE_SKIP',
      changeDialogView: 'coursesScoredPoints/CHANGE_DIALOG_VIEW',
      // setCurrentItem: 'coursesScoredPoints/SET_ITEM',
      // setList: 'coursesScoredPoints/SET_LIST',
      setCourseUsersIds: 'courses/SET_COURSE_USERS_IDS'
    }),
    // changePagination (index) {
    //   this.changeSkip(this.limit * (index - 1))
    //   this.$vuetify.goTo(0)
    //   this.fetchList(this.filters)
    // },
    changeDialog (status = false, item = null) {
      this.setCurrentItem(item)
      this.changeDialogView(status)
    },
    changeDialogDelete (item) {
      this.removingItem = item
      this.removingItem.courseId = this.$route.params.course_id
      this.showRemoveDialog = !this.showRemoveDialog
    },
    removeLocal () {
      this.remove(this.removingItem).then(() => {
        this.showRemoveDialog = false
        this.$toasted.success(this.$t('success_deleted'))
      })
    },
    // sendRequest: debounce(function () {
    //   this.fetchList(this.filters)
    // }, 1000),
    createRequestPayload () {
      const data = new FormData()
      data.append('user_id', this.search.id)
      data.append('role_id', this.roleId)
      return data
    },
    submit () {
      this.create({ data: this.createRequestPayload(), courseId: this.$route.params.course_id }).then(() => {
        this.$toasted.success(this.$t('success_created'))
        this.changeDialogView()
      })
    },
    changeInputUsers: debounce(function (val) {
      if (!val) return
      if (this.search.fullName !== val) {
        this.fetchUsers({
          keywords: val,
          countries: null
        })
      }
    }, 500),
    autoCompleteChangedUser (e) {
      this.search = e
    }
  },
  destroyed () {
    this.setList([])
    this.setCourseUsersIds([])
    this.changeSkip(0)
  }
}
