import mixins from '@/mixins'
import validation from '@/mixins/validation'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import course from '@/mixins/course'
import { snakeCase, find } from 'lodash'

export default {
  name: 'setting-layouts-general',
  mixin: [course, mixins, validation],
  data () {
    return {
      valid: false,
      payload: {
        name: '',
        statusId: '',
        layoutId: '',
        countryId: '',
        key: ''
      }
    }
  },

  computed: {
    ...mapGetters({
      currentCourse: 'coursesInfo/info',
      isLoading: 'coursesEmailTemplates/isLoading',
      currentItem: 'coursesEmailTemplates/currentItem',
      settings: 'templates/settings',
      list: 'coursesEmailTemplates/list',

      basicCountries: 'setting/basicCountries',

      layouts: 'settingLayouts/list',
      isLayoutsListLoading: 'settingLayouts/isListLoading',

      // templateTypes: 'coursesEmailTemplates/templateTypes'
    }),
  },
  created () {
    this.fetchSettings().then(() => {
		if (this.currentItem) {
			this.setData()
		}
    });

    if (!this.layouts.length) this.fetchLayouts()
    if (!this.basicCountries.length) this.fetchBasicCountries()

	this.$on('event-save', () =>{
	  this.submit();
	});
  },
  methods: {
    ...mapActions({
      update: 'coursesEmailTemplates/UPDATE',
      create: 'coursesEmailTemplates/CREATE',
      fetchSettings: 'templates/GET_SETTING',
      fetchLayouts: 'settingLayouts/GET_LIST',
      fetchBasicCountries: 'setting/GET_BASIC_COUNTRIES'
    }),
    ...mapMutations({
      setLayoutsList: 'settingLayouts/SET_LIST'
    }),
    setData () {
      if (this.currentItem.isCustom){
		  this.payload.keyOrigin = this.currentItem.event;
		  Object.assign(this.payload, this.currentItem);

		  this.payload.name = this.currentItem.template.data.name;
		  this.payload.key = this.currentItem.template.data.key;
      } else {
		  this.payload.keyOrigin = this.currentItem.key;
		  Object.assign(this.payload, this.currentItem);

		  this.payload.name += ` - ${this.currentCourse.name}`;
		  this.payload.key += `_${this.currentCourse.slug.split('-').join('_')}`;
      }

		this.payload.countryId = this.currentCourse.countryId;
		this.payload.layoutId = this.currentItem.template.data.layoutId;
		this.payload.typeId = this.currentItem.template.data.typeId;
		this.payload.courseId = this.currentCourse.id;

		let statusItem = find(this.settings.templates.statuses, ['title', this.currentItem.template.data.status]);
		
		this.payload.statusId = statusItem.id;

    },
    createRequestPayload () {
      const formData = new FormData()

      for (const i in this.payload) {
        formData.append(snakeCase(i), this.payload[i] || '')
      }

      if (this.currentItem.isCustom){
		  formData.append('_method', 'patch');

		  // formData.delete('key');
		  // formData.append('key', this.payload.keyOrigin);
      } else {
		  formData.append('origin_template_id', this.payload.templateId);
	  }

      return formData
    },
    submit () {
      this.$refs.form.validate()
      if (this.valid) {
        if (this.currentItem.id) {
          this.update(this.createRequestPayload()).then(() => {
            this.$toasted.success(this.$t('success_updated'));
            this.setData();
          })
        } else {
          this.create(this.createRequestPayload()).then(() => {
            this.$toasted.success(this.$t('success_created'));
		  })
        }
      }
    }
  },
  destroyed () {
    this.setLayoutsList([])
  }
}
