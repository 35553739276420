import { mapGetters, mapMutations } from 'vuex'

import General from '../general/index.vue'

export default {
  name: 'courses-settings-scored-points-dialog-view',
  data () {
    return {
      dialog: true
    }
  },
  components: {
    General
  },
  computed: {
    ...mapGetters({
      currentItem: 'coursesCertificates/currentItem',
      openDialogView: 'coursesCertificates/openDialogView'
    })
  },
  methods: {
    ...mapMutations({
      changeDialogView: 'coursesCertificates/CERTIFICATES_CHANGE_DIALOG_VIEW',
      setCurrentItem: 'coursesCertificates/CERTIFICATES_SET_ITEM'
    }),
    closeDialog () {
      this.dialog = false
      this.changeDialogView(false)
    }
  },
  destroyed () {
    this.setCurrentItem(null)
  }
}
