import validation from '@/mixins/validation'
import { mapActions, mapGetters } from 'vuex'
import { snakeCase } from 'lodash'
import MainEditor from '@/components/editor/index.vue'
export default {
  name: 'setting-layouts-translations-item',
  props: {
    locale: {}
  },
  components: {
    MainEditor
  },
  mixin: [validation],
  data () {
    return {
      valid: false,
      payload: {
        locale: this.locale,
        content: '',
        subject: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      currentItem: 'coursesEmailTemplates/currentItem',
		isLoading: 'coursesEmailTemplates/isLoading',
    }),
    currentTranslation () {
      if (!this.currentItem.template.data.translations) return null;
      return this.currentItem.template.data.translations.data.find(e => e.locale === this.locale)
    }
  },
  created () {
    if (this.currentTranslation) {
      Object.assign(this.payload, this.currentTranslation)
    }
  },
  methods: {
    ...mapActions({
      create: 'coursesEmailTemplates/CREATE_TRANSLATIONS',
      update: 'coursesEmailTemplates/UPDATE_TRANSLATIONS'
    }),
    createRequestPayload () {
      const formData = new FormData()

      for (const i in this.payload) {
        formData.append(snakeCase(i), this.payload[i] || '')
      }
      if (this.currentTranslation && this.currentTranslation.hasOwnProperty('id')) {
        formData.append('_method', 'patch')
        formData.append('id', this.currentTranslation.id)
      }

      formData.append('template_id', this.currentItem.templateId);
      formData.append('course_id', this.currentItem.courseId);
      return formData
    },
    submit () {
      this.$refs.form.validate()
      if (this.valid) {
        if(!this.payload.id){
			this.create(this.createRequestPayload()).then(() => {
				this.$toasted.success(this.$t('success_updated'))
			});

        } else {
			this.update(this.createRequestPayload()).then(() => {
				this.$toasted.success(this.$t('success_updated'))
			})
        }

      }
    }
  }
}
