import { mapActions, mapGetters, mapMutations } from 'vuex'

import { debounce } from 'lodash'
import { courseSettingScoredPointsTableHead } from '@/modules/courses/helpers/setting'

import DialogView from '../dialog-view/index.vue'

export default {
  name: 'courses-settings-scored-points-list',
  data () {
    return {
      page: 1,
      removingItem: null,
      showRemoveDialog: false,
      filters: {
        keywords: '',
        courseId: this.$route.params.course_id
      }
    }
  },
  components: {
    DialogView
  },
  computed: {
    ...mapGetters({
      list: 'coursesScoredPoints/list',
      limit: 'coursesScoredPoints/limit',
      listLength: 'coursesScoredPoints/listLength',
      isLoading: 'coursesScoredPoints/isLoading',
      isListLoading: 'coursesScoredPoints/isListLoading',
      openDialogView: 'coursesScoredPoints/openDialogView'
    }),
    courseSettingScoredPointsTableHead
  },
  created () {
    this.fetchList(this.filters)
  },
  methods: {
    ...mapActions({
      fetchList: 'coursesScoredPoints/GET_LIST',
      remove: 'coursesScoredPoints/DELETE'
    }),
    ...mapMutations({
      changeSkip: 'coursesScoredPoints/CHANGE_SKIP',
      changeDialogView: 'coursesScoredPoints/CHANGE_DIALOG_VIEW',
      setCurrentItem: 'coursesScoredPoints/SET_ITEM',
      setList: 'coursesScoredPoints/SET_LIST',
      setCourseUsersIds: 'courses/SET_COURSE_USERS_IDS'
    }),
    changePagination (index) {
      this.changeSkip(this.limit * (index - 1))
      this.$vuetify.goTo(0)
      this.fetchList(this.filters)
    },
    changeDialog (status = false, item = null) {
      this.setCurrentItem(item)
      this.changeDialogView(status)
    },
    changeDialogDelete (item) {
      this.removingItem = item
      this.removingItem.courseId = this.$route.params.course_id
      this.showRemoveDialog = !this.showRemoveDialog
    },
    removeLocal () {
      this.remove(this.removingItem).then(() => {
        this.showRemoveDialog = false
        this.$toasted.success(this.$t('success_deleted'))
      })
    },
    sendRequest: debounce(function () {
      this.fetchList(this.filters)
    }, 1000)
  },
  destroyed () {
    this.setList([])
    this.setCourseUsersIds([])
    this.changeSkip(0)
  }
}
