import { mapActions, mapGetters, mapMutations } from 'vuex'
import { debounce, snakeCase } from 'lodash'

import validation from '@/mixins/validation'

import SelectPackages from '@/components/forms/SelectPackages'
import datePickerComponent from '@/components/date-picker/index.vue'

export default {
    name        : 'courses-settings-certificates-general',
    mixins      : [validation],
    data () {
        return {
            valid           : false,
            validationErrors: {},
            search          : '',
            searchEn        : '',
            searchInput     : '',
            searchInputEn   : '',
            currentItemEn   : null,
            menu            : false,
            menu2           : false,
            payload         : {
                name                : '',
                description         : '',
                points              : '',
                statusId            : '',
                templateKey         : '',
                templateKeyEn       : '',
                packages            : [],
                studyingStartedAt   : '',
                studyingFinishedAt  : '',
                typeId              : ''
            },
            payloadEn       : {
                name                : '',
                description         : '',
                points              : '',
                statusId            : '',
                templateKey         : '',
                //templateEnKey       : '',
                packages            : [],
                studyingStartedAt   : '',
                studyingFinishedAt  : '',
                typeId              : ''
            }
        }
    },
    components  : {
        SelectPackages,
        datePickerComponent
    },
    computed    : {
        ...mapGetters({
            currentItem         : 'coursesCertificates/currentItem',
            //currentItemEn       : 'coursesCertificates/currentItemEn',
            
            isLoading           : 'coursesCertificates/isLoading',
            openDialogView      : 'coursesCertificates/openDialogView',
            packagesList        : 'coursesPackages/list',
            
            templates           : 'settingTemplates/list',
            templatesLoading    : 'settingTemplates/isListLoading',
            
            templatesEn         : 'settingTemplates/listEn',
            templatesEnLoading  : 'settingTemplates/isListEnLoading',
            
            setting             : 'courses/setting'
        })
    },
    created () {
        var templateEn = null;
        
        if(this.currentItem){
            if(
                this.currentItem.templateEn && 
                this.currentItem.templateEn.data && 
                this.currentItem.templateEn.data.name
            ){
                templateEn = this.currentItem.templateEn.data;
            }
        }
        
        this.changeFilterTemplates({
            type    : 'keywords',
            value   : this.currentItem ? this.currentItem.template.data.name : ''
        });
        
        this.changeFilterTemplates({
            type    : 'keywords_en',
            value   : templateEn ? templateEn.name : ''
        });
        
        if (this.currentItem) {
            this.search = this.currentItem.template.data;
            
            if(templateEn){
                this.searchEn = templateEn;
            }
            
            this.setData();
        };
        
        this.fetchTemplates({
            type: 'course_certificate'
        });
        
        this.fetchTemplatesEn({
            type: 'course_certificate'
        });
    },
    methods     : {
        ...mapActions({
            fetchTemplates          : 'settingTemplates/GET_LIST',
            fetchTemplatesEn        : 'settingTemplates/GET_LIST_EN',
            
            create                  : 'coursesCertificates/CERTIFICATES_CREATE',
            update                  : 'coursesCertificates/CERTIFICATES_UPDATE'
        }),
        ...mapMutations({
            changeDialogView        : 'coursesCertificates/CERTIFICATES_CHANGE_DIALOG_VIEW',
            changeFilterTemplates   : 'settingTemplates/CHANGE_LIST_FILTER'
        }),
        setData () {
            const item = this.currentItem;
            
            if(item && item.packages && item.packages.data){
                item.packages = item.packages.data.map(e => e.id);
            }
            
            Object.assign(this.payload, item);
        },
        setDataEn () {
            const item = this.currentItemEn;
            
            if(item && item.packages && item.packages.data){
                item.packages = item.packages.data.map(e => e.id);
            }
            
            Object.assign(this.payloadEn, item);
        },
        changeInputTemplates    : debounce(function (val) {
            //if (this.templates.length && !val) return;
            
            if(!val && this.search && this.search.name){
                this.changeFilterTemplates({
                    type    : 'keywords',
                    value   : ''
                });
                
                this.fetchTemplates({
                    type: 'course_certificate'
                });
                
                return;
            }
            
            if (this.search.name !== val) {
                this.changeFilterTemplates({
                    type    : 'keywords',
                    value   : val
                });
                
                this.fetchTemplates({
                    type: 'course_certificate'
                })
            }
        }, 500),
        changeInputEnTemplates  : debounce(function (val) {
            //if (this.templatesEn.length && !val) return;
            
            if(!val && this.searchEn && this.searchEn.name){
                this.changeFilterTemplates({
                    type    : 'keywords_en',
                    value   : ''
                });
                
                this.fetchTemplatesEn({
                    type: 'course_certificate'
                });
                
                return;
            }
            
            if (this.searchEn.name !== val) {
                this.changeFilterTemplates({
                    type    : 'keywords_en',
                    value   : val
                });
                
                this.fetchTemplatesEn({
                    type: 'course_certificate'
                })
            }
        }, 500),
        autoCompleteChangedTemplate (e) {
            if (!e) {
                if(false){
                    Object.keys(this.payload).forEach(t => {
                        this.payload[t] = ''
                    });
                }
                
                this.search     = '';
                //this.searchEn   = '';
                
                this.payload.templateKey    = '';
                //this.payload.templateKeyEn  = '';
            } else {
                this.search = e;
                this.payload.templateKey = e.key;
            }
        },
        autoCompleteChangedTemplateEn (e) {
            if (!e) {
                if(false){
                    Object.keys(this.payloadEn).forEach(t => {
                        this.payloadEn[t] = ''
                    });
                }
                
                this.searchEn = '';
                this.payload.templateKeyEn = '';
            } else {
                this.searchEn = e;
                
                this.payload.templateKeyEn = e.key;
            }
        },
        createRequestPayload () {
            const formData = {};
            
            Object.keys(this.payload).forEach(t => {
                formData[snakeCase(t)] = this.payload[t]
            });
            
            //formData[snakeCase('templateKeyEn')] = this.payloadEn['templateKey'];
            
            formData.courseId = this.$route.params.course_id;
            
            if (this.currentItem) {
                formData._method = 'patch';
            };
            
            return formData;
        },
        submit () {
            this.$refs.form.validate();
            
            //this.createRequestPayload();
            //return;
            
            if (this.valid) {
                if (this.currentItem) {
                    this.update(this.createRequestPayload())
                    .then(() => {
                        this.$toasted.success(this.$t('success_updated'))
                        this.changeDialogView(false)
                    })
                } else {
                    this.create(this.createRequestPayload())
                    .then(() => {
                        this.$toasted.success(this.$t('success_created'))
                        this.changeDialogView(false)
                    })
                }
            }
        }
    }
}
