import {mapActions, mapGetters, mapMutations} from 'vuex'
import {camelCase, debounce} from 'lodash'

import validation from '@/mixins/validation'

export default {
    name: 'courses-settings-scored-points-general',
    mixins: [validation],
    data() {
        return {
            valid: false,
            validationErrors: {},
            search: '',
            searchInput: '',
            payload: {
                points: '',
                description: '',
                fullName: '',
                userId: ''
            }
        }
    },
    computed: {
        ...mapGetters({
            users: 'user/users',
            usersLoading: 'user/usersLoading',
            currentItem: 'coursesScoredPoints/currentItem',
            isLoading: 'coursesScoredPoints/isLoading',
            openDialogView: 'coursesScoredPoints/openDialogView',
        })
    },
    created() {
        this.setData()
    },
    methods: {
        ...mapActions({
            fetchUsers: 'user/GET_USERS',
            create: 'coursesScoredPoints/CREATE',
            update: 'coursesScoredPoints/UPDATE'
        }),
        ...mapMutations({
            changeDialogView: 'userPositions/CHANGE_DIALOG_VIEW'
        }),
        setData() {
            const transformObj = {}
            const item = this.currentItem
            for (const i in item) {
                transformObj[camelCase(i)] = item[i]
            }
            Object.assign(this.payload, transformObj)
        },
        changeInputUsers: debounce(function (val) {
            if (!val) return
            if (this.search.fullName !== val) {
                this.fetchUsers({
                    keywords: val
                })
            }
        }, 500),
        autoCompleteChangedUser(e) {
            if (!e) {
                Object.keys(this.payload).forEach(t => {
                    this.payload[t] = ''
                })
                this.search = ''
            } else {
                this.search = e
                this.payload.userId = e.id
            }
        },
        createRequestPayload() {
            const data = new FormData()
            data.append('description', this.payload.description)
            data.append('courseId', this.$route.params.course_id)
            data.append('points', this.payload.points)

            if (this.currentItem) {
                data.append('id', this.currentItem.id)
                data.append('_method', 'patch')
            } else {
                data.append('user_id', this.payload.userId)
            }
            return data
        },
        submit() {
            this.$refs.form.validate()
            if (this.valid) {
                if (this.currentItem) {
                    this.update(this.createRequestPayload()).then(() => {
                        this.$toasted.success(this.$t('success_updated'))
                        this.changeDialogView(false)
                    })
                } else {
                    this.create(this.createRequestPayload()).then(() => {
                        this.$toasted.success(this.$t('success_created'))
                        this.changeDialogView(false)
                    })
                }
            }
        },
        filterUsersAutoComplete(item, queryText) {
            console.log(item, queryText)
            return item.fullName.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 || item.email.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
        }
    }
}
