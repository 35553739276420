import { mapGetters } from 'vuex'
import {map, find} from 'lodash';
import item from './item/index.vue'

export default {
  name: 'setting-layouts-translations',
  data () {
    return {
      currentLocale: ''
    }
  },
  computed: {
    ...mapGetters({
      currentCourse: 'coursesInfo/info',
      basicLocales: 'setting/basicLocales'
    }),
    basicLocaleslist(){
      let locales = [this.currentCourse.locale];

      return map(locales, locale => {
        return find(this.basicLocales, ['locale', locale]);
      });
    }
  },
  components: {
    item
  },
  created () {
    this.currentLocale = this.basicLocales[0].locale
  },
  methods: {}
}
