
export default {
  name: 'date-picker',
  props: {
    title: {
      type: String,
      default: ''
    },
    date: {
      type: String,
      default: ''
    },
    minDate: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      modal: false,
      localDate: this.date
    }
  },
  watch: {
    localDate(){
      this.$emit('update', this.localDate)
    }
  }
}
