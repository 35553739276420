import { mapActions, mapGetters, mapMutations } from 'vuex'

import {find, debounce, assign } from 'lodash'
import { courseSettingsEmailTemplatesTableHead } from '@/modules/courses/helpers/setting'

import DialogView from '../dialog-view/index.vue'

export default {
  name: 'courses-settings-certificates-list',
  data () {
    return {
      removingItem: null,
      showRemoveDialog: false
    }
  },
  components: {
    DialogView
  },
  computed: {
    ...mapGetters({
      currentCourse: 'coursesInfo/info',
      list: 'coursesEmailTemplates/list',
      isListLoading: 'coursesEmailTemplates/isListLoading',
      isLoading: 'coursesEmailTemplates/isLoading',
      limit: 'coursesEmailTemplates/limit',
      listLength: 'coursesEmailTemplates/listLength',
      openDialogView: 'coursesEmailTemplates/openDialogView'
    }),
    courseSettingsEmailTemplatesTableHead,

    templatesList(){
      let list = [];
      let notyList = (this.currentCourse && this.currentCourse.notifications) ? this.currentCourse.notifications.data : [];
      let index = 1;

      this.list.forEach(el => {
        let notyItem = find(notyList, notyEl => {
          return notyEl.event == el.key;
        });

        if(notyItem){
			list.push(assign(notyItem,{
			  name: el.name,
			  isCustom: true,
              index: index++
            }));
        } else {
			list.push(assign(el,{
			  index: index++
            }));
        }
      });
      return list;
    },
  },
  created () {
    this.fetchList({ courseId: this.$route.params.course_id })
  },
  methods: {
    ...mapActions({
      fetchList: 'coursesEmailTemplates/GET_LIST',
      remove: 'coursesEmailTemplates/DELETE',
      removeEmailTemplate: 'coursesEmailTemplates/DELETE_EMAIL_TEMPLATE'
    }),
    ...mapMutations({
      changeDialogView: 'coursesEmailTemplates/CHANGE_DIALOG_VIEW',
      setCurrentItem: 'coursesEmailTemplates/SET_ITEM',
      setList: 'coursesEmailTemplates/SET_LIST',
      changeFilterTemplates: 'settingTemplates/CHANGE_LIST_FILTER'
    }),
    changeDialog (status = false, item = null) {
      this.setCurrentItem(item)
      this.changeDialogView(status)
    },
    changeDialogDelete (item) {
      this.removingItem = item
      this.removingItem.courseId = this.$route.params.course_id
      this.showRemoveDialog = !this.showRemoveDialog
    },
    removeLocal () {
      this.remove(this.removingItem).then(() => {
        this.showRemoveDialog = false
        this.$toasted.success(this.$t('success_deleted'));

		  this.removeEmailTemplate(assign(this.removingItem, {
			  _method: 'delete'
		  }));
      });
    },
    sendRequest: debounce(function () {
      this.fetchList({ courseId: this.$route.params.course_id })
    }, 1000),

    changeDialogRemove(item){
      console.log('delete', item);
    }
  },
  destroyed () {
    this.setList([])
    this.changeFilterTemplates({
      key: 'keywords',
      value: ''
    })
  }
}
